<template>
  <LoaderCircle></LoaderCircle>
</template>
<script>
import LoaderCircle from "@/components/LoaderCircle.vue";
import { mapActions } from "vuex";
export default {
  components: {
    LoaderCircle,
  },
  async created() {
    let data = {
      token: this.$route.query.token,
      user_id: this.$route.query.user_id,
      verify_email: this.$route.query.verify_email,
    };
    await this.apiVerifyEmail(data);
  },
  methods: {
    ...mapActions("auth", ["apiVerifyEmail"]),
  },
};
</script>